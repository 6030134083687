import React from "react";
import LinkIcon from '../Static/link.svg';



export default function App() {
    return (
        <div>
        <div className="footer">
          <button className='footer-btn'><span>$XIAOCAT ALL RIGHTS RESERVED</span></button>

          <div className="footer-right">
            <a href="https://t.me/xiaojiecatsol" target='_blank'><button className='btn-light'><span><img src = {LinkIcon} alt="telegram"></img> <span id="padding">TELEGRAM</span></span></button></a>
            <a href="https://twitter.com/XiaojieCatSol" target='_blank'><button className='btn-light'><span><img src = {LinkIcon} alt="twitter"></img> <span id="padding">TWITTER</span></span></button></a>
          </div>
        </div> 
        </div>
    )
}
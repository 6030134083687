import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer'
// Removed unused imports
import Xiao1 from '../Static/angryxiao.png';
import Xiao2 from '../Static/hungryxiao.png';
import Xiao3 from '../Static/straightupxiao.png';
import XiaoJeet from '../Static/xiaojeet.png';
import XiaoJeet2 from '../Static/xiaojeet2.png';
import XiaoJeet3 from '../Static/xiaojeet3.png';
import XiaoJeet4 from '../Static/standingxiao.png'
import rocketXiao from '../Static/rocketxiao.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export default function Home() {
  return (
    <div>
      <Header />
      <div className='container3'>
        <p className='big-font'>$XIAOCAT</p>
        <p className='small-text'>XIAOJIE HAS COME TO SAVE SOLANA, XIAOJIE CAT IS HERE TO BRING GENERATIONAL WEALTH TO ALL.</p>
      </div>
      <div className='xiaoImages'>
        <div className='xiaoImg'><img src={Xiao1} alt="don't mess with xiaojie" /><p className='small-text'>don't mess with xiaojie</p></div>
        <div className='xiaoImg'><img src={Xiao2} alt="jeet eater xiaojie" /><p className='small-text'>jeet eater xiaojie</p></div>
        <div className='xiaoImg'><img src={Xiao3} alt="staring contest with xiaojie" /><p className='small-text'>staring contest with xiaojie</p></div>
      </div>
      <div className='container2'>
        <p className='pump-button'><a className='pump-button' href='https://dexscreener.com/solana/28YiJ34YPVfZA4BUS5Lyo5SpnxWdzJsud1e11cYgaQGL' target='_blank' rel="noopener noreferrer">DEXSCREENER</a></p>
      </div>
      <div className='container4'>
        <p className='caption'>TO DEX, RAYDIUM, BINANCE, COINBASE, INFINITY AND BEYOND.</p>
        <img src={rocketXiao} alt="rocket xiao" />
      </div>
      <div className='container5'>
        <p className='caption'>THE XIAOJIE IN ACTION</p>
        <Swiper
          className="mySwiper"
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={50}
          autoplay={{
            delay: 2000, // Delay in milliseconds between slides. Adjust as needed.
            disableOnInteraction: true, // Continue autoplay after user interaction
          }}        
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          effect="cards"
        >
          <SwiperSlide><img src={XiaoJeet} alt="Xiao Jeet" /><p className='small-text'>Xiaojie sharing his firm opinions</p></SwiperSlide>
          <SwiperSlide><img src={XiaoJeet2} alt="Xiao Jeet 2" /><p className='small-text'>Xiaojie, iced out in diamonds, nicely asking the jeets to stop, a rare occurence</p></SwiperSlide>
          <SwiperSlide><img src={XiaoJeet3} alt="Xiao Jeet 3" /><p className='small-text'>Xiaojie, impatient, ready to DESTROY his breakfeast</p></SwiperSlide>
          <SwiperSlide><img src={XiaoJeet4} alt="Xiao Jeet 4" /><p className='small-text'>Xiaojie standing on business</p></SwiperSlide>
        </Swiper>
      </div>
      <Footer />
    </div>
  );
}
